import { If } from '@itau-loans-www/shopping/src/components'
import Layout from '@itau-loans-www/shopping/src/layouts/Offer'

import Banner from './components/Banner'
import OtherProposals from './components/OtherProposals'
import './offers.scss'
import { useOffers } from './useOffers'

const Offers = () => {
  const { offerlessChannel, name, hero, others, errorTitle, hasHero } =
    useOffers()

  return (
    <Layout>
      <Banner
        offerlessChannel={offerlessChannel}
        leadName={name}
        errorTitle={errorTitle}
        hasHero={hasHero}
        proposal={hero}
      />
      <div className="container">
        <div id="body-offers">
          <If
            condition={others?.length > 0}
            renderIf={
              <OtherProposals
                offerlessChannel={offerlessChannel}
                proposals={others}
              />
            }
          />
        </div>
      </div>
    </Layout>
  )
}

export default Offers
