import { Helmet } from 'react-helmet'

import {
  Header,
  If,
  MobileHeader
} from '@itau-loans-www/shopping/src/components'
import { useMediaQuery } from '@itau-loans-www/shopping/src/hooks'
import classnames from 'classnames'

import MainLayout from '../Main'
import './offer.scss'

export default ({ children, className, ...props }) => {
  const classNamesLayout = classnames('offer-layout', {
    [className]: className
  })

  const classNamesContent = classnames('offer-content', {
    [className]: className
  })

  const isDesktop = useMediaQuery('desktop')
  const HeaderOffer = () => (
    <If
      condition={isDesktop}
      renderIf={<Header backRoute="/" />}
      renderElse={<MobileHeader isGrayColor />}
    />
  )

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div {...props} className={classNamesLayout}>
        <MainLayout Header={HeaderOffer} currentPage="offer">
          <main className={classNamesContent}>{children}</main>
        </MainLayout>
      </div>
    </>
  )
}
