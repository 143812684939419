export const CAROUSEL_CONFIG = {
  perView: 1.86,
  focusAt: 0,
  gap: 20,
  animationDuration: 900,
  breakpoints: {
    1000: {
      focusAt: 'center',
      gap: 30,
      animationDuration: 400,
      peek: {
        before: 10,
        after: 150
      }
    },
    780: {
      perView: 2.25
    },
    576: {
      perView: 1.25,
      gap: 10
    },
    426: {
      perView: 1.1,
      gap: 10,
      peek: {
        before: 0,
        after: 45
      }
    },
    391: {
      perView: 1,
      gap: 10,
      peek: {
        before: 0,
        after: 28
      }
    },
    376: {
      perView: 1,
      gap: 10,
      peek: {
        before: 0,
        after: 20
      }
    },
    374: {
      perView: 1,
      gap: 10,
      peek: {
        before: 0,
        after: 0
      }
    }
  }
}
