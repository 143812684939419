import { SecondOffer } from '@itau-loans-www/shopping/src/components'
import { useMediaQuery } from '@itau-loans-www/shopping/src/hooks'
import { Carousel, CarouselItem } from '@mobi/ds'

// import { PROPOSALS } from '../mock'
import { CAROUSEL_CONFIG } from './constants'

const CardCarousel = ({ proposals }) => {
  const isMobile = useMediaQuery('mobileLandscape')

  const isLongProposalList = proposals?.length >= 3

  const ableUX = isMobile ? proposals?.length >= 2 : isLongProposalList

  const isCarouselType = !isMobile && isLongProposalList

  const options = {
    ...CAROUSEL_CONFIG,
    type: isCarouselType ? 'carousel' : 'slider'
  }

  return (
    <div className="card-carousel">
      <Carousel
        indentifier="other-proposals"
        variant="slider"
        bullets={ableUX}
        arrows={false}
        transition={ableUX}
        options={options}
        className="other-proposals__carousel"
      >
        {proposals?.map((proposal, idx) => (
          <CarouselItem key={`${idx}-carousel`}>
            <SecondOffer
              className="--carousel"
              proposal={proposal}
              index={idx}
            />
          </CarouselItem>
        ))}
      </Carousel>
    </div>
  )
}

export default CardCarousel
