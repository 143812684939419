import Helmet from 'react-helmet'

import { COOKIEBAR_MFE_URL } from 'gatsby-env-variables'

import { IdsAlert } from '@ids/react'
import { If, Footer, Feedback } from '@itau-loans-www/shopping/src/components'
import { useLocation } from '@reach/router'

import SEO from './components/SEO'
import useMain from './useMain'

export default ({ children, Header, hasFooter }) => {
  const location = useLocation()
  const {
    title,
    alertContent,
    isAlertActive,
    setIsAlertActive,
    isLoaded,
    styles
  } = useMain({
    location
  })

  return (
    <>
      <SEO title={title} />
      <Feedback />
      <IdsAlert
        setShow={setIsAlertActive}
        variant={alertContent?.type}
        show={isAlertActive}
        icon={alertContent?.icon}
        header={alertContent?.title}
      >
        {alertContent?.description}
      </IdsAlert>
      {Header && <Header />}
      {children}
      <If condition={hasFooter} renderIf={<Footer />} />
      <If
        condition={isLoaded}
        renderIf={
          <>
            <Helmet>
              <link rel="stylesheet" href={`${COOKIEBAR_MFE_URL}/styles.css`} />
              <script src={`${COOKIEBAR_MFE_URL}/runtime.js`}></script>
              <script src={`${COOKIEBAR_MFE_URL}/polyfills.js`}></script>
              <script
                src={`${COOKIEBAR_MFE_URL}/main.js`}
                onLoad={`saveCookieBarStylesToStorage()`}
              ></script>
              {styles && <style id="banner-cookies-internal">{styles}</style>}
            </Helmet>

            <itau-cookie-consent-banner segment="varejo"></itau-cookie-consent-banner>
          </>
        }
      />
    </>
  )
}
