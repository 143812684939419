import { useEffect } from 'react'

import { useLead } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'

export const useOffers = () => {
  const {
    leadData: { name },
    offers: { hero, others, errorTitle }
  } = useLead()

  const offerlessChannel = !!hero?.offerlessChannel

  const isValidProductCode = typeof hero?.productCode === 'string'
  const hasHero = isValidProductCode && !offerlessChannel

  useEffect(() => {
    analytics.track('pageLoad', { currentPage: 'vitrine-shopping' })
  }, [])

  return {
    offerlessChannel,
    name,
    hero,
    others,
    errorTitle,
    hasHero
  }
}
