import { useMemo } from 'react'

import { If } from '@itau-loans-www/shopping/src/components'
import classnames from 'classnames'

import CardAlone from './components/CardAlone'
import CardCarousel from './components/CardCarousel'
// import { PROPOSALS } from './components/mock'
import './other-proposals.scss'

const OtherProposals = ({ proposals, offerlessChannel }) => {
  const proposalsQuantity = proposals?.length

  const isAbleToCarousel = proposalsQuantity > 1

  const containerClasses = useMemo(
    () =>
      classnames('other-proposals', {
        'other-proposals--blocked': offerlessChannel,
        'other-proposals--is-alone-card': !isAbleToCarousel
      }),
    [offerlessChannel, isAbleToCarousel]
  )

  return (
    <section className={containerClasses}>
      <div className="other-proposals__content">
        <div className="other-proposals__col">
          <p className="other-proposals__title">
            Confira outras ofertas de crédito
          </p>
        </div>
        <If
          condition={isAbleToCarousel}
          renderIf={<CardCarousel proposals={proposals} />}
          renderElse={<CardAlone proposal={proposals?.[0] || {}} />}
        />
      </div>
    </section>
  )
}

export default OtherProposals
