import { If, HeroOffer } from '@itau-loans-www/shopping/src/components'
import classnames from 'classnames'

import './banner.scss'

const Banner = ({
  leadName = '',
  errorTitle,
  offerlessChannel = false,
  hasHero,
  proposal
}) => {
  const firstName = leadName.split(' ')[0]

  const bannerClasses = classnames('banner-offer', {
    'banner-offer--offerless': offerlessChannel
  })

  const titleClasses = classnames('banner-offer__title', {
    'banner-offer__title--offerless': offerlessChannel
  })

  const mapErrorTitle = {
    not_allowed: `Desculpe, não conseguimos validar a autorização no app FGTS`,
    error: `${firstName}, houve um erro na validação da autorização do app FGTS`,
    ineligible: `${firstName}, não encontramos nenhuma oferta disponível no Saque-Aniversário FGTS`
  }

  const isIneligible = errorTitle === 'ineligible'

  const proposalFormatted = {
    ...proposal,
    errorType: errorTitle
  }

  return (
    <section
      className="banner-offer__background"
      data-cy="cy-banner-offer"
      data-testid="banner-offer"
    >
      <div className={bannerClasses}>
        <div className="banner-offer__wrapper">
          <h1 className={titleClasses}>
            <If
              condition={errorTitle}
              renderIf={mapErrorTitle[errorTitle]}
              renderElse={`${firstName}, encontramos a oferta ideal para você`}
            />
          </h1>
          <If condition={isIneligible}>
            <p className="banner-offer__subtitle">
              Conheça outras soluções de crédito que temos no Itaú
            </p>
          </If>
        </div>
        <If
          condition={hasHero}
          renderIf={<HeroOffer proposal={proposalFormatted} />}
        />
      </div>
    </section>
  )
}

export default Banner
